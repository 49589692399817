import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { emptyGrave, getGraves, GraveData, SearchQueryType } from "../../services/api";


export function useStateGrave(): GraveData {

    const [grave, setGrave] = useState<GraveData>(emptyGrave);
    const { id, name } = useParams<{ id: string, name: string }>()
    const stateGrave = useHistory().location.state as any || emptyGrave

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {

        async function loadGrave(gid: string, name: string) {

            const searchQuery: SearchQueryType = {
                graveids: [gid],
                fullname: name,
                birth: { min: 1700, max: 2100, exact: '', useRange: false },
                death: { min: 1700, max: 2100, exact: '', useRange: false },
                congregationid: ""
            }

            const data = await getGraves(searchQuery, 1, 0);

            setLoading(false);

            if (data.items.length === 0) {
                console.warn(`No results found for id ${gid}`);
                return;
            }

            if (data.items.length > 1) {
                console.warn(`Too many results found for grave id ${gid}`);
                // setErrCode("toomanyresults");
                // setDataState("ERROR");
                return;
            }


            if (data.items[0]) {
                if (data.items[0]) {
                    setGrave(data.items[0])
                }

                // setGraveData(data.items[0]);
                // setErrCode(null);
                // setDataState("DONE");
            }

        }
        if (!loading) {
            if (stateGrave.graveid === "" && grave.graveid === "") {
                setLoading(true);
                loadGrave(id, name);
            } else {

                if (stateGrave.graveid !== "" && stateGrave.graveid === id && grave.graveid !== stateGrave.graveid) {
                    setGrave(stateGrave);
                }
            }
        }


    }, [id, name, stateGrave, loading, grave]);

    return grave;

}