import React from 'react'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import { useHistory } from 'react-router-dom'

export function LanguageChangeMenu() {
    const { t, i18n } = useTranslation()
    const [anchorEl, setAnchorEl] = React.useState(null)
    let history = useHistory()

    const handleClick = (event: any) => {
      setAnchorEl(event.currentTarget);
    }
  
    const handleClose = () => {
      setAnchorEl(null);
    }

    const setLanguage = (language: string) => {
        setAnchorEl(null)
        i18n.changeLanguage(language)
        history.push(history.location.pathname.slice(3))
    }

    return (
        <>
            <Button color='inherit' 
                    endIcon={<KeyboardArrowDownIcon />}
                    aria-controls="language-menu" 
                    aria-haspopup="true" 
                    aria-label={t('aria-select-language')}
                    onClick={handleClick}>
                {t('change-language')}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={()=> {
                    setLanguage('fi')
                }}
                >
                    {t('finnish')}
                </MenuItem>
                <MenuItem onClick={()=> {
                    setLanguage('sv')
                }}
                >
                    {t('swedish')}
                </MenuItem>
                <MenuItem onClick={()=> {
                    setLanguage('en')
                }}
                >
                    {t('english')}
                </MenuItem>
            </Menu>
        </>
    )
}