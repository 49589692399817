import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
          marginTop: theme.spacing(2),
        },
    }
}))

interface GraveListPaginationProps {
    page: number
    count: number
    handlePageChange: any
}

export function GraveListPagination({page, count, handlePageChange}: GraveListPaginationProps) {
    const classes = useStyles()

    return (
        <>
            { count > 1 &&
                <Grid container direction='row' justifyContent='center' className={classes.root}>
                    <Pagination count={count} page={page} variant="outlined" shape="rounded" onChange={handlePageChange}/>
                </Grid>
            }
        </>
    )
}
