import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import fi from "./locales/fi.json";
import sv from "./locales/sv.json";
import en from "./locales/en.json"
import detector from "i18next-browser-languagedetector";
import XHR from "i18next-http-backend";


// the translations
// (tip move them in a JSON file and import them)
const resources = {
    fi,
    sv,
    en
};

i18n
  .use(XHR)
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'fi',
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: '.', 
    whitelist: ['fi','en','sv'],
    detection: {
      order: ['path'],
      lookupFromPathIndex: 0,
      checkWhitelist: true
    },

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;