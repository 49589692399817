import React from 'react'
import { Grid, Hidden} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    page: {
        borderTop: '3px solid #D3D3D3'
    },
    pageContent: {
        backgroundColor: '#FFFFFF',
        paddingBottom: '20px',
    },
    sidepanel: {
        padding: '0px',
        backgroundColor: '#FFFFFF'
    },
    sidepanelContent: {
        backgroundColor: '#e7eef3',
        padding: '5px 8% 40px',
    },
}))

interface SidepanelViewProps {
    pageContent: JSX.Element,
    sidepanelContent: JSX.Element
}

export function SidepanelView({ pageContent, sidepanelContent }: SidepanelViewProps) {
    const classes = useStyles()
    return (
        <div className={classes.page}>
            <Grid container spacing={0} alignItems='stretch' >
                <Grid item xs={12} md={8} className={classes.pageContent} >
                    {pageContent}
                </Grid>
                <Hidden smDown>
                    <Grid item xs={4} className={classes.sidepanel} >
                        {sidepanelContent}
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    )
}