import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSIGHT_CONN_STRING
    }
});
appInsights.loadAppInsights();

const tracked: Set<string> = new Set();

/**
 * Track event
 * 
 * Identical event is tracked only once, until the page is reloaded or refreshed.
 * 
 * @param eventName eventname as astring
 * @returns 
 */
function trackEvent(eventName: string): void {
    if (tracked.has(eventName)) return;
    tracked.add(eventName);
    appInsights.trackEvent({ name: eventName });
}



export { appInsights, trackEvent };