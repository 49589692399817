import React, {useState} from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    largePhoto: {
        width: '100%',
        height: 'auto',
        marginBottom: '-5px'
    },
}))

export function PhotoBanner() {
    const classes = useStyles()
    const theme = useTheme()
    const { t } = useTranslation()

    const isSmall = useMediaQuery(theme.breakpoints.down('sm'))
    const url = isSmall ? process.env.REACT_APP_SMALL_PHOTO_URL : process.env.REACT_APP_LARGE_PHOTO_URL
    const showPhoto =  url && url.length > 0 

    const month = ('0' + (new Date().getMonth()+1)).slice(-2)
    const photoUrl = url + (url?.endsWith('/') ? '' : '/') + month + '.jpg'
    
    const emptyStringArray : string[] = []
    const [notFoundPhotos, setNotFoundPhotos] = useState(emptyStringArray)
    
    function photoUrlError(error: any) {
        if( !notFoundPhotos.includes(photoUrl) ) {
            setNotFoundPhotos([...notFoundPhotos, photoUrl])
            console.log('Main view photo not found from url: '+photoUrl)
            // could use default image
            // event.target.src = `/images/${isSmall? 'small' : 'large'}_default_photo.jpg`
        }        
    }

    return ( showPhoto && !notFoundPhotos.includes(photoUrl) ?
                <img className={classes.largePhoto} 
                    onError={(e) => photoUrlError(e)} 
                    src={photoUrl} 
                    alt={t('beautiful-scenery-from-graveyard')} /> :
                null
    )
}