import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    imageLarge: {
        margin: '10%',
        width: '80%',
        height: 'auto',
    },
    imageSmall: {
        width: '140px',
        height: 'auto',
        [theme.breakpoints.up('md')]: {
            width: '180px',
          },
    }
}))

interface LogoImageProps {
    size?: 'large' | 'small'
    image: string
}

function LogoImage({size, image}: LogoImageProps) {
    const classes = useStyles()
    return (
        <img
            className={size === 'small' ? classes.imageSmall : classes.imageLarge}
            src={image}
            alt={'logo'}
            aria-hidden
        />
    )
}

interface LogoProps {
    size?: 'large' | 'small',
    link?: string
    image: string
}

export function Logo({size, link, image}: LogoProps) {
    return (
        <>
            {link && (
                <Link href={link} target='_blank' >
                    <LogoImage size={size} image={image} />
                </Link>
            )}
            {!link && <LogoImage size={size} image={image} />}
        </>
    )
}
