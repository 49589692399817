import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    box: {
        backgroundColor: '#e7eef3',
        padding: '5px 8% 40px',
    },
  }));

export function Sidepanel(props: {children: JSX.Element, title: string}) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <div className={classes.box}>
            <h2>{t('filter-title')}</h2>
            {props.children}
        </div>
    )
}