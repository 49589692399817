import React from 'react'
import {GraveData} from '../../services/api'
import { 
    Button, 
    Grid, 
    Typography
} from '@material-ui/core'
import { 
    Close
} from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import {
    BornBlackIcon,
    CrossBlackIcon} from '../common/icons'

const useStyles = makeStyles(() => ({
    box: {
        width: '100%', 
        marginBottom: '5px', 
        backgroundColor: '#e7eef3',
        padding: '25px',
    },
    dataItem: {
        textAlign: 'left',
        padding: '5px 20px',
        fontSize: '80%'
    },
    icon: {
        marginRight: '7px',
        marginBottom: '3px'
    },
    item: {
        marginTop: '5px',
    },
    emptyIcon: {
        marginRight: '28px'
    },
    linkButton: {
        justifyContent: 'left', 
        float: 'right',
        margin: '0 0 20px 10px'
    },
    yearItems: {
        display: 'flex',
        justifyContent :'flex-start',
        alignItems: 'flex-start',
        marginBottom:'2em',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    yearItem: {
        display: 'flex',
        alignContent: 'flex-end',
        margin: '0 10px'
    }
  }))

interface SameGraveList {
    items: GraveData[],
    handleSameGraveClicked: (e: any, setVisible: boolean) => void
    isSameGraveExpanded: boolean
    setIsSameGraveExpanded: (isSameGraveExpanded: boolean) => void
}

let uniqueKey : number = 0

export default function SameGraveList({ items, handleSameGraveClicked, isSameGraveExpanded, setIsSameGraveExpanded }: SameGraveList) {
    const classes = useStyles()
    const { t } = useTranslation()
    const noResults = items.length === 0

    return (
        <div className={classes.box} id="buriedSameGrave" >
                <Button
                    aria-label={t('close') + t('buried-in-same-grave')}
                    aria-expanded={isSameGraveExpanded}
                    className={classes.linkButton}
                    startIcon={<Close style={{marginBottom: '3px'}}/>}
                    onClick={(e) => {
                        setIsSameGraveExpanded(false)
                        handleSameGraveClicked(e, false)
                    }}
                    >
                        {t('close')}
                </Button>

                <Typography variant='h5' component='h1' align='left' display='block'>
                    {t('buried-in-same-grave')}
                </Typography>

                { items.map((grave) => <SameGraveRow key={`samegraverow${++uniqueKey}`} grave={grave} />)}
                { noResults && <p style={{textAlign:'left'}}>{t('no-people-buried-in-same-grave')}</p>}
        </div>
    )
}

export function SameGraveRow({grave} : { grave: GraveData }) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <Grid container>
        <Grid item xs={12} md={6} className={classes.dataItem}><h2>{grave.fullname}</h2></Grid>
        <Grid item xs={12} md={6} className={classes.yearItems}>
            <YearItem icon={<BornBlackIcon fontSize='small' className={classes.icon}/>} 
                                header={t('birth-year')} 
                                data={grave.birth} />
            <YearItem icon={<CrossBlackIcon fontSize='small' className={classes.icon}/>} 
                                header={t('death-year')}
                                data={grave.death} />
                
        </Grid>
        </Grid>

    )
}

interface YearItem {
    icon?: any,
    header: string 
    data: string | number
}

function YearItem({icon, header, data}: YearItem) {
    const classes = useStyles()

    return (
        <div className={classes.yearItem}>
                {icon ? icon : <span className={classes.emptyIcon}>{'\u00A0'}</span> }
            
            <span className={classes.item}>{header}</span>
            
            <span className={classes.item} style={{marginLeft: '1em'}}>
                {data}
            </span>
        </div>
    )
}

