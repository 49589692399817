import React, { useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useTranslation } from 'react-i18next'
import {GraveData, SearchQueryType, Congregation} from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import { 
  Button, 
  Hidden, 
  Drawer, 
  Grid,
  Paper,
  IconButton,
  Container,
  TextField, 
  Popper
  } from '@material-ui/core'
import { FiltersBlueIcon } from '../common/icons'
import { FilterModal } from '../filters/FilterModal'

const useStyles = makeStyles((theme) => ({
  form: {
    backgroundColor: '#FFFFFF'
  },
  paper: {
    [theme.breakpoints.up('md')]: {
      width: '100%',
      marginLeft: '10px'
    },
    padding: '0px 4px',
    display: 'flex',
    alignItems: 'center',
    border: '1px solid gray',
    width: 'calc(100% - 54px - 10px)',
    marginRight: '10px'
  
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1
  },
  filterButton: {
    border: '1px solid gray',
    borderRadius: '4px',
    minWidth: '1em',
    fontSize: '2.3em',
    lineHeight: '1em',
    padding: '2px 10px'
  },
  filterIcon: {
    fontSize: '1em'
  },
  iconButton: {
    padding: 3,
  },
  renderOption: {
    [theme.breakpoints.up('md')]: {
      textAlign: 'right', 
    },
    fontStyle: 'italic'
  },
  renderListItem : {
    minWidth: '95%',
    '@media (min-width:450px)': {
      minWidth: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 'auto'
    },
  },
  renderPopper : {
    minWidth: '95%',
    '@media (min-width:450px)': {
      minWidth: '80%'
    },
    [theme.breakpoints.up('sm')]: {
      minWidth: 'auto'
    },
  },
  container: {
    paddingTop: '10px',
    paddingBottom: '10px',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      width: '94%',
      margin: '0 3%'      
    },
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100% - 20px)',
      margin: '0 10px',
      }
  }
}));

interface SearchBarViewProps {
    graves: GraveData[]
    handleSubmit: (e: React.ChangeEvent<{}>) => void
    handleChange: (e: React.ChangeEvent<{}>, newValue: string | GraveData | null, reason: string) => void
    handleTextChange: (e: any) => void
    handleFilterChange: (newQuery: SearchQueryType) => void
    searchQuery: SearchQueryType
    popupOpen: boolean
    setPopupOpen: (value: boolean) => void
}

export function SearchBar({graves, handleSubmit, handleChange, handleTextChange, handleFilterChange, searchQuery, popupOpen, setPopupOpen}: SearchBarViewProps) {
  const [showFilters, setShowFilters] = useState(false);
  const classes = useStyles()
  const { t } = useTranslation()

  return (
      <div>
          <form onSubmit={handleSubmit} className={classes.form}>
              <Container className={classes.container}>
                  <Paper className={classes.paper}>
                      <Autocomplete
                          id='search-bar'
                          freeSolo
                          className={classes.input}
                          options={graves}
                          value={searchQuery.fullname}
                          open={popupOpen}
                          openOnFocus={true}
                          onOpen={() => setPopupOpen(true)}
                          onClose={() => setPopupOpen(false)}
                          getOptionLabel={(option) => option.fullname || searchQuery.fullname}
                          onChange={handleChange}
                          renderInput={(params: any) => (
                              <TextField {...params} InputProps={{...params.InputProps, disableUnderline: true}} label={t('search-grave')} onChange={handleTextChange} />
                          )}
                          renderOption={(option) => (
                            <SearchBarItem fullname={option.fullname} graveid={option.graveid} />
                          )}
                          PopperComponent={(props) => (<Popper {...props} className={classes.renderPopper}/>)}
                      />
                      <span>
                          <IconButton type='submit' aria-label='search' onClick={handleSubmit}>
                              <SearchIcon />
                          </IconButton>
                      </span>
                  </Paper>
                  <Hidden mdUp>
                      <Button className={classes.filterButton} onClick={() => setShowFilters(!showFilters)}>
                          <FiltersBlueIcon size='1em' className={classes.filterIcon} />
                      </Button>
                  </Hidden>
              </Container>
          </form>
          <Drawer open={showFilters} anchor='top' onClose={() => setShowFilters(false)}>
              <FilterModal
                  setShowFilters={setShowFilters}
                  searchParameters={searchQuery}
                  handleFilterChange={handleFilterChange}
                  handleSubmit={handleSubmit}
              />
          </Drawer>
      </div>
  )
}

function SearchBarItem({fullname, graveid}: {fullname: string, graveid: string}) {
  const classes = useStyles()
  const { t } = useTranslation()
  const graveyard = graveid.split('-')[0]
  let congregations : Congregation[] = t('graveyards', { returnObjects: true })
  const congregation = congregations.find(congregation => congregation.graveyards.some(item => item.id === graveyard));
  const graveyardName = congregation?.graveyards.find(grave => grave.id === graveyard)?.name

  return (
    <Grid container className={classes.renderListItem}>
      <Grid item xs={12} md={8}>{fullname}</Grid>      
      <Grid item xs={12} md={4} className={classes.renderOption}>{congregation? congregation.name + ", " : ""} {graveyardName}</Grid>
    </Grid>
  )
}
