import React from 'react'
import { AppBar, Toolbar } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Logo } from '../common/Logo'
import { Link, useMediaQuery, Grid } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"
const KIRKKO_HELSINGISSA_URL_SMALL = '/images/tyhja-logo.svg'

const useStyles = makeStyles((theme) => ({
    appbar: {
        borderTop: '3px solid #85B5D5',
        padding: '10px 20px 25px 20px'
    },
    links: {
        marginLeft: 'auto',
        fontWeight: 'bold',
        fontSize: '0.7rem'
    },
    link: {
        marginLeft: '20px',
        color: '#85B5D5'
    },
    smallContainer: {
        marginTop: '10px'
    },
    smallContainerItem: {
        marginBottom: '15px'
    }
  }))

export function Footer () {
    const classes = useStyles()
    const history = useHistory()
    const { t } = useTranslation()
    const smallScreen = useMediaQuery('(max-width:450px)');

    return (
        <AppBar position='static' elevation={0} color='secondary' className={classes.appbar}>
            <Toolbar>
                { smallScreen ? 
                    <Grid container direction="column" justify="center" alignItems="center" className={classes.smallContainer}>
                        <Grid item className={classes.smallContainerItem}>
                            <Logo size='small' image={KIRKKO_HELSINGISSA_URL_SMALL} link={t('church-url')}/>
                        </Grid>
                        <Grid item className={classes.smallContainerItem}>
                            <Link href={t('privacy-statement-url')}
                                aria-label={t('move-to-page') + t('privacy-statement-url')}
                                target='_blank' 
                                className={classes.link} >
                                {t('privacy-statement')}
                            </Link>
                        </Grid>
                        <Grid item className={classes.smallContainerItem}>
                            <Link href="/accessibility" 
                                    className={classes.link} 
                                    onClick={(event: React.SyntheticEvent) => {
                                        event.preventDefault()
                                        history.push('/accessibility')
                                    }}>
                                {t('accessibility-statement')}
                            </Link>
                        </Grid>
                    </Grid>
                    :
                    <>
                        <Logo size='small' image={KIRKKO_HELSINGISSA_URL_SMALL} link={t('church-url')}/>
                        <div className={classes.links}>
                            <Link href={t('privacy-statement-url')}
                                    aria-label={t('move-to-page') + t('privacy-statement-url')}
                                    target='_blank' 
                                    className={classes.link} >
                                {t('privacy-statement')}
                            </Link>
                            <Link href="/accessibility" 
                                    className={classes.link} 
                                    onClick={(event: React.SyntheticEvent) => {
                                        event.preventDefault()
                                        history.push('/accessibility')
                                    }}>
                                {t('accessibility-statement')}
                            </Link>
                        </div>
                    </> 
                }
            </Toolbar>
        </AppBar>
    )
}