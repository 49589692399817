import React from 'react'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'
import {LanguageChangeMenu} from './LanguageChangeMenu' 
import HeaderNavigation from './HeaderNavigation'
import { NotVoid } from 'lodash'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from "react-router-dom"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 0,
      color:  '#005293',
      backgroundColor: '#005293'
    },
    title: {
      color: '#FFFFFF',
      marginRight: theme.spacing(2),
      cursor: 'pointer'
    },
  }),
)

interface HeaderProps {
  activePage: number | false,
  setActivePage: (page: number | false) => NotVoid
}

export function Header({activePage, setActivePage}: HeaderProps) {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div className={classes.root}>
      <Container >
        <AppBar position="static" elevation={0}>
          <Toolbar>
            <Link to="/home" component={RouterLink}>
              <Typography variant="h6" 
                    className={classes.title}>
                {t('service-name','Hautahaku.fi')}
              </Typography>
            </Link>
            <LanguageChangeMenu />
            <HeaderNavigation activePage={activePage} setActivePage={setActivePage}/>
          </Toolbar>
        </AppBar>
      </Container>
    </div>
  )
}
