import React, { useEffect, useState, useRef } from 'react'
import { SearchBar } from './SearchBar'
import { useHistory } from 'react-router-dom'
import { getGraves, GraveData, SearchQueryType, emptySearchQuery, SEARCH_LIMIT } from '../../services/api'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import graveListAtom from '../../atoms/graveList'

const initialState: GraveData[] = []

export function SearchBarContainer() {
  const [graveList, setGraveList] = useRecoilState(graveListAtom);
  const [searchQuery, setSearchQuery] = useState({ ...graveList.searchQuery })
  const [totalHits, setTotalHits] = useState<number>(graveList.totalHits)
  const [error, setError] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [items, setItems] = useState(initialState)
  const [popupOpen, setPopupOpen] = useState(false)
  let history = useHistory()
  const lastAbortController: any = useRef()
  const { t } = useTranslation()

  // needed to use filters in autocomplete
  useEffect(() => {
    setSearchQuery({ ...graveList.searchQuery, fullname: searchQuery.fullname })
  }, [graveList, searchQuery.fullname])

  function fetchData(searchParameters: SearchQueryType, limit: number) {
    setIsLoaded(false)

    if (lastAbortController.current) {
      lastAbortController.current.abort()
    }

    // Create new AbortController for the new request and store it in the ref
    const currentAbortController = new AbortController()
    lastAbortController.current = currentAbortController

    getGraves(searchParameters, limit, 0, currentAbortController.signal)
      .then((result: { totalcount: number, items: GraveData[] }) => {
        if (currentAbortController.signal.aborted) {
          return
        }
        setItems(result && result.items ? result.items : initialState)
        setTotalHits(result ? result.totalcount : graveList.totalHits)
        setIsLoaded(true)
      },
        (error) => {
          if (currentAbortController.signal.aborted) {
            return
          }
          setIsLoaded(true)
          setError(true)
        }
      )
  }

  function handleSubmit(e: React.ChangeEvent<{}>) {
    e.preventDefault()
    setPopupOpen(false)
    const graves = isLoaded ? [...items] : []
    setGraveList({ graves: graves, totalHits: totalHits, searchQuery: searchQuery, limit: SEARCH_LIMIT, isLoaded: false })
    history.push(`/list`)
  }

  function handleChange(e: React.ChangeEvent<{}>, newValue: string | GraveData | null, reason: string) {
    if (typeof newValue === 'object' && reason === 'select-option' && newValue != null) {
      setPopupOpen(false)
      setSearchQuery({ ...searchQuery, fullname: newValue["fullname"] })
      const selectedGrave = items.filter(i => i != null && i["fullname"] === newValue["fullname"] && i["graveid"] === newValue["graveid"])
      setGraveList({ graves: selectedGrave, totalHits: 1, searchQuery: { ...emptySearchQuery, fullname: selectedGrave[0].fullname }, limit: SEARCH_LIMIT, isLoaded: true })
      history.push(`/list`)
    }
    else if (typeof newValue === 'string') {
      setSearchQuery({ ...searchQuery, fullname: newValue })
    }
    if ('clear' === reason) {
      setPopupOpen(false)
    }
  }

  function handleTextChange(e: any) {
    const oldValue: string = searchQuery.fullname
    const newValue: string = e.target.value || ''
    const search = { ...searchQuery, fullname: newValue }
    setSearchQuery(search)
    const endsWithSpace = newValue.length > 0 && newValue.charAt(newValue.length - 1) === ' '
    if (endsWithSpace && oldValue === newValue.slice(0, newValue.length - 1)) {
      return;
    }
    if (e.target.value.length > 1) {
      setPopupOpen(true)
      fetchData(search, 15)
    }
    else {
      setPopupOpen(false)
      setItems([])
      setIsLoaded(false)
    }
  }

  function handleFilterChange(newQuery: SearchQueryType) {
    setSearchQuery({ ...newQuery })
  }

  return (
    <>
      <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
        <Alert onClose={() => setError(false)} severity="error">
          {t('gravelist-error')}
        </Alert>
      </Snackbar>
      <SearchBar
        graves={items}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        handleTextChange={handleTextChange}
        searchQuery={searchQuery}
        handleFilterChange={handleFilterChange}
        popupOpen={popupOpen}
        setPopupOpen={setPopupOpen}
      />
    </>
  )
}
