import React from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useTranslation } from 'react-i18next'
import { useHistory } from "react-router-dom"
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'

function TabPanel(props: any) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`page-tabpanel-${index}`}
      aria-labelledby={`page-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index: any) {
  return {
    id: `page-tab-${index}`,
    'aria-controls': `page-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  tabRoot: {
    marginLeft: 'auto',
    backgroundColor: 'inherit',
  },
  tabs: {
    boxShadow: 'none',
  },
  menuButton: {
    marginLeft: 'auto',
  },
}))

interface HeaderNavigationProps {
  activePage: number | false,
  setActivePage: (page: number | false) => void
  width: any
}

export function HeaderNavigation({activePage, setActivePage, width}: HeaderNavigationProps) {
  const classes = useStyles()
  const { t } = useTranslation()
  let history = useHistory()

  const handleChange = (event: any, newValue: any) => {
    setActivePage(newValue);
  }

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (isWidthUp('md', width)) {
    return (
      <div className={classes.tabRoot}>
        <Tabs 
          className={classes.tabs} 
          value={activePage} 
          onChange={handleChange} 
          aria-label={t('aria-select-page')}
          indicatorColor='secondary'>
          <Tab label={t('tab-frontpage', 'Etusivu')} 
                {...a11yProps(0)} 
                onClick={e => {
                  e.preventDefault()
                  history.push("/home")
              }}/>
          <Tab label={t('tab-info-service', 'Tietoja hautahausta"')} 
                {...a11yProps(1)} 
                onClick={e => {
                  e.preventDefault()
                  history.push("/info")
              }}/>
          <Tab label={t('tab-info-contact','Yhteystiedot')} 
                {...a11yProps(2)} 
                onClick={e => {
                  e.preventDefault();
                  history.push("/contact")
              }}/>
        </Tabs>
      </div>
    )
  } else {
    return (
      <>
        <IconButton edge="start" 
                    className={classes.menuButton} 
                    color="inherit" 
                    aria-label={t('aria-select-page')}
                    aria-controls="main-menu" 
                    aria-haspopup="true" 
                    onClick={handleClick}>
          <MenuIcon />
        </IconButton>
        <Menu id="main-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
        >
          <MenuItem onClick={e => {
            e.preventDefault()
            history.push("/home")
            handleClose()
            }}
          >
            {t('tab-frontpage')}
          </MenuItem>
          <MenuItem onClick={e => {
            e.preventDefault()
            history.push("/info")
            handleClose()
            }}
          >
            {t('tab-info-service')}
          </MenuItem>
          <MenuItem onClick={e => {
            handleClose()
            history.push("/contact")
            handleClose()
            }}
          >
            {t('tab-info-contact')}
          </MenuItem>
        </Menu>
      </>
    )
  }
}

export default withWidth()(HeaderNavigation)
