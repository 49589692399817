import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    centered: {
        textAlign: 'center'
    },
    smallLabel : {
        verticalAlign: 'middle',
        textTransform: 'uppercase',
        display: 'flex',
        alignItems: 'center'
    },
    text : {
        fontSize: '1em',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        marginBottom: '4px'
    },
    icon : {
        fontSize: '0.9em',
        paddingBottom: '2px'
    }

}))

interface SmallLabelProps {
    icon?: JSX.Element,
    text: string
}

export function SmallLabel({icon, text}: SmallLabelProps) {
    const classes = useStyles()
    return (
        <div className={classes.smallLabel}>
            <div className={classes.icon}>
                {icon}
            </div>
            <div className={classes.text}>
                {text}
            </div>
        </div>
    )
}
