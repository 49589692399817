import { makeStyles } from '@material-ui/core';
import React from 'react'
import Carousel from "react-material-ui-carousel"
import { Logo } from "./Logo"

const useStyles = makeStyles((theme) => ({
    carousel: {
        minHeight: '300px'
    },
}))

export function LogoCarousel() {
    const classes = useStyles()

    const importAll = (files: any) => {
        return files.keys().map(files);
    };
    
    const logos = importAll(
        require.context("../../../public/images/logos", false)
    )

return (
    <Carousel className={classes.carousel} navButtonsAlwaysInvisible={logos.length <= 1} indicators={false}>
        {logos.map((logo: string, index: number) => 
            <Logo key={index} image={logo}/>
        )}
    </Carousel>
)
}