import React, { useEffect, Suspense } from 'react'
import { MainViewView } from './components/mainView/MainViewView'
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom'
import { MapViewView } from './components/mapView/MapViewView'
import { AccessibilityStatementView } from './components/accessibilityStatement/AccessibilityStatementView'
import { Header } from './components/header/Header'
import { Footer } from './components/footer/Footer'
import { GraveListContainer } from './components/graveListView/GraveListContainer'
import { ServiceInfoViewView } from './components/serviceInfoView/ServiceInfoViewView'
import { ContactViewView } from './components/contactView/ContactViewView'
import { MuiThemeProvider } from '@material-ui/core/styles/'
import { SearchBarContainer } from './components/searchBar/SearchBarContainer'
import { makeStyles } from '@material-ui/core/styles'
import { Container } from '@material-ui/core'
import CssBaseline from "@material-ui/core/CssBaseline";
import theme from './themes/kirkko'
import { warmupBackend } from './services/api'
import { useTranslation } from 'react-i18next'
import { RecoilRoot } from 'recoil';
import { trackEvent } from './services/applicationInsight'

import './i18n';
import './App.css'

// This number must be changed to the correct value manually when new version is published.
console.log("Version: 1.1.0");

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    paddingLeft: '0px',
    paddingRight: '0px',
    backgroundColor: '#005293',
    [theme.breakpoints.up('md')]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  mapViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('xs')]: {
      position: 'fixed',
      zIndex: 1,
      top: '0px'
    }
  }
}));

const initialPage: number | false = 0

trackEvent("Page Load");

function App() {
  const { i18n } = useTranslation()
  const [activePage, setActivePage] = React.useState(initialPage)
  const classes = useStyles()

  useEffect(() => {
    warmupBackend()
      .then((result: any) => { },
        (error: any) => { })
  }, [])

  return (
    <div className="App">
      <RecoilRoot>
        <Router>
          <MuiThemeProvider theme={theme}>
            <CssBaseline />
            <Suspense fallback=''>
              <Header activePage={activePage} setActivePage={setActivePage} />
              <SearchBarContainer />
              <Switch>
                <Route path="/map/:id/:name">
                  <Container className={classes.contentContainer + " pageContentContainer " + classes.mapViewContainer}>
                    <MapViewView />
                  </Container>
                </Route>
                <Route>
                  <Container className={classes.contentContainer + " pageContentContainer"}>
                    <Route exact path="/"><Redirect to={"/" + i18n.language} /></Route>
                    <Route exact path="/:lng" render={(props) =>
                      <MainViewView {...props} setActivePage={setActivePage} />} />
                    <Route path="/home" ><Redirect to={"/" + i18n.language + "/home"} /></Route>
                    <Route path="/list" ><Redirect to={"/" + i18n.language + "/list"} /></Route>
                    <Route path="/info" ><Redirect to={"/" + i18n.language + "/info"} /></Route>
                    <Route path="/contact" ><Redirect to={"/" + i18n.language + "/contact"} /></Route>
                    <Route path="/accessibility" ><Redirect to={"/" + i18n.language + "/accessibility"} /></Route>
                    <Route path="/:lng/home" render={(props) =>
                      <MainViewView {...props} setActivePage={setActivePage} />} />
                    <Route path="/:lng/list" render={(props) =>
                      <GraveListContainer {...props}  setActivePage={setActivePage} />}
                    />
                    <Route path="/:lng/info" render={(props) => <ServiceInfoViewView {...props} setActivePage={setActivePage} />} />
                    <Route path="/:lng/contact" render={(props) => <ContactViewView {...props} setActivePage={setActivePage} />} />
                    <Route path="/:lng/accessibility" render={(props) => <AccessibilityStatementView {...props} setActivePage={setActivePage} />} />
                  </Container>
                </Route>
              </Switch>
              <Container>
                <Footer />
              </Container>
            </Suspense>
          </MuiThemeProvider>
        </Router>
      </RecoilRoot>
    </div>
  );
}

export default App
