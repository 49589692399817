import React from 'react'
import { useTranslation } from 'react-i18next'
import { emptySearchQuery, SearchQueryType } from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { YearFilter } from './YearFilter'
import { Grid, Button, Container, Paper, FormHelperText } from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import { GraveYardFilter } from './GraveYardFilter'
import { BornBlackIcon, CrossBlackIcon } from '../common/icons'
import { MAX_YEAR } from '../../services/api'

const useStyles = makeStyles(() => ({
    form: {
        backgroundColor: '#FFFFFF',
    },
    paper: {
        margin: '5px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        right: '20px',
        top: '20px',
        width: 'auto',
        padding: '14px',
    },
    clearButton: {
        justifyContent: 'left',
        textAlign: 'left'
    },
    container: {
        background: '#e7eef3',
        padding: '0px',
        textAlign: 'start',
    },
    icon: {
        marginRight: '7px',
        marginBottom: '3px',
    },
}))

interface FilterPanelProps {
    handleFilterChange: (searchParameters: SearchQueryType) => void
    searchParameters: SearchQueryType
}

export function FilterPanel({ searchParameters, handleFilterChange }: FilterPanelProps) {
    const classes = useStyles()
    const { t } = useTranslation()

    function changeYears(
        field: 'birth' | 'death',
        newValue: { min: number; max: number; exact: number | string; useRange: boolean }
    ) {
        const newParameters =
            field === 'birth' ? { ...searchParameters, birth: newValue } : { ...searchParameters, death: newValue }
        handleFilterChange(newParameters)
    }

    function changeGraveYards(newValue: string[]) {
        handleFilterChange({ ...searchParameters, graveids: newValue })
    }

    function changeCongregation(newValue: string) {
        searchParameters.congregationid = newValue
        handleFilterChange(searchParameters)
    }

    function isDeadBeforeBorn () {
        const minBirthYear = searchParameters.birth.useRange ? searchParameters.birth.min : searchParameters.birth.exact
        const maxDeathYear = searchParameters.death.useRange ? searchParameters.death.max : searchParameters.death.exact
        return minBirthYear !== '' && maxDeathYear !== '' &&  maxDeathYear < minBirthYear

    }
    function yearErrors() {
        // do not limit the min year in exact fields, just show no results
        const birthExact: boolean = searchParameters.birth.exact !== '' && 
                                    (
                                        searchParameters.birth.exact > MAX_YEAR 
                                        || (searchParameters.birth.useRange === false && isDeadBeforeBorn())
                                    )
        const deathExact: boolean = searchParameters.death.exact !== '' &&
                                    (   
                                        searchParameters.death.exact > MAX_YEAR 
                                        || (!searchParameters.death.useRange && isDeadBeforeBorn())
                                    )    
                                    
        const birthRangeStart: boolean = false
        const birthRangeEnd: boolean = searchParameters.birth.max < searchParameters.birth.min ||
                                        (searchParameters.birth.useRange && isDeadBeforeBorn())
        const deathRangeStart: boolean = false
        const deathRangeEnd: boolean = searchParameters.death.max < searchParameters.death.min || 
                                        (searchParameters.death.useRange && isDeadBeforeBorn())
        
        const errors = [birthExact, deathExact, birthRangeStart, birthRangeEnd, deathRangeStart, deathRangeEnd]
        return errors
    }
    
    // based on useRange, show error of exact / range fields
    function hasVisibleError(errors: boolean[]) {
        if( errors[0] === true && searchParameters.birth.useRange === false) {
            return true
        }
        if( errors[1] === true && searchParameters.death.useRange === false) {
            return true
        }
        if( (errors[3] === true || errors[4] === true ) && searchParameters.birth.useRange ) {
            return true
        }
        if( (errors[5] === true || errors[6] === true ) && searchParameters.birth.useRange ) {
            return true
        }
        return false
    }

    return (
        <Container className={classes.container}>
            <Paper className={classes.paper}>
                <Grid container spacing={2} alignItems='center'>
                    <Grid item xs={12}>
                        <Button onClick={() => handleFilterChange({ ...emptySearchQuery, fullname: searchParameters.fullname })} 
                                color='primary'
                                className={classes.clearButton}
                                startIcon={<Clear />}>
                            <b>{t('remove-filters')}</b>
                        </Button>
                        <hr style={{ borderColor: '#eeeeee', borderWidth: '1px' }} />
                        { hasVisibleError(yearErrors()) && <FormHelperText error>{t('filter-error')}</FormHelperText> }
                    </Grid>
                    <Grid item xs={12}>
                        <YearFilter searchParameters={searchParameters} 
                                    changeYears={changeYears}
                                    field='birth' 
                                    label={t('birth-year')}
                                    icon={<BornBlackIcon fontSize='small' className={classes.icon} />}
                                    yearErrors={yearErrors()} 
                                    />
                    </Grid>
                    <Grid item xs={12}>
                        <YearFilter searchParameters={searchParameters} 
                                    changeYears={changeYears} 
                                    field='death'
                                    label={t('death-year')}
                                    icon={<CrossBlackIcon fontSize='small' className={classes.icon} />}
                                    yearErrors={yearErrors()} />
                    </Grid>
                </Grid>

                <GraveYardFilter searchParameters={searchParameters} changeGraveYards={changeGraveYards} changeCongregation={changeCongregation} />
            </Paper>
        </Container>
    )
}
