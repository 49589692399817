import { SearchQueryType, GraveData} from "./api"

interface CacheItem {
  searchString: string
  totalcount: number
  items: GraveData[]
}

let queueCache : CacheItem[] = []
const maxCache : number = 50

function makeString(query: SearchQueryType) {
  return query.fullname + 
    query.birth.min + query.birth.max + query.birth.exact + query.birth.useRange + 
    query.death.min + query.death.max + query.death.exact + query.death.useRange + 
    query.graveids.join('')
}

export function getFromCache (searchQuery: SearchQueryType, limit: number, offset: number | null) {
    
    const searchString = makeString(searchQuery) + limit + offset
    const index = queueCache.findIndex(item => item.searchString === searchString)
    if( index === -1 ) {
      return undefined
    }

    const result = { totalcount: queueCache[index].totalcount, items: queueCache[index].items }

    // order the found match as last in queue
    const updateCacheItem = queueCache[index]
    queueCache.splice(index, 1)
    queueCache.push(updateCacheItem)
    return result
}

export function addToCache(searchQuery: SearchQueryType, limit: number, offset: number | null, totalcount: number, items: GraveData[]) {

  const cacheItem = { 
    searchString : makeString(searchQuery) + limit + offset,
    totalcount: totalcount,
    items: items
  }
  if( queueCache.length > maxCache ) {
    // remove the first
    queueCache.shift()
  }
  queueCache.push(cacheItem)
}
  