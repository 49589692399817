import React from 'react'
import { useTranslation } from 'react-i18next'
import GraveRow from './GraveRow'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { GraveData } from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { SidepanelView } from '../common/SidepanelView'
import { sameGraveListType } from './GraveListContainer'

const useStyles = makeStyles((theme) => ({
    listViewHeader: {
        textAlign: 'left',
        padding: '20px 40px',
    },
    list: {
        listStyleType: 'none',
        paddingLeft: '0px',
        width: '100%'
    },
}))

interface GraveListViewViewProps {
    items: GraveData[]
    isLoaded: boolean
    totalHits: number
    sameGraveList: sameGraveListType[]
    handleSameGraveListChange: (i: number, e:string, setVisible: boolean, setSameGraveIsLoaded: any) => void
    pagination: JSX.Element
    filters: JSX.Element
}

export function GraveListViewView({ items, isLoaded, totalHits, sameGraveList, handleSameGraveListChange, pagination, filters }: GraveListViewViewProps) {
    return (
        <SidepanelView
            pageContent = {
                <PageContent 
                    items={items} 
                    isLoaded={isLoaded} 
                    totalHits={totalHits} 
                    sameGraveList={sameGraveList}
                    handleSameGraveListChange={handleSameGraveListChange} 
                    pagination={pagination} 
                />
            }
            sidepanelContent = {filters}
        />
    )
}

interface PageContentProps {
    items: GraveData[]
    isLoaded: boolean
    totalHits: number
    sameGraveList: sameGraveListType[]
    handleSameGraveListChange: (i: number, e:string, setVisible: boolean, setSameGraveIsLoaded: any) => void
    pagination: JSX.Element
}


function PageContent ({ items, isLoaded, totalHits, sameGraveList, handleSameGraveListChange, pagination }: PageContentProps) {
    const { t } = useTranslation()
    const classes = useStyles()
    const oneOrManyHits = totalHits === 1 ? t('hit') : t('hits')
    const maxExpandCount = 3

    return (
        <>
            <div className={classes.listViewHeader} tabIndex={0}>
                <Typography variant='h5' component='h1' align='left' display='block'>
                    {t('search-results')}
                </Typography>
                <Typography variant='body2' align='left' display='block'>
                    {totalHits + ' ' + oneOrManyHits}
                </Typography>
            </div>
            {!isLoaded && <CircularProgress />}
            {items.length === 0 && isLoaded && <p>{t('gravelist-empty')}</p>}
            {items.length > 0 && (
                <Grid container spacing={0}>
                    <ul className={classes.list}>
                        {items !== undefined &&
                            items.map((grave: GraveData, index: number) => (
                                <li key={index} tabIndex={0}>
                                    {' '}
                                    <GraveRow 
                                        key={`graverow-${JSON.stringify(grave)}`} 
                                        grave={grave}
                                        index={index} 
                                        sameGraveList={sameGraveList}
                                        handleSameGraveListChange={handleSameGraveListChange} 
                                        showMore={items.length <= maxExpandCount}
                                    />{' '}
                                </li>
                            ))}
                    </ul>
                </Grid>
            )}
            {pagination}
        </>
    )
}

