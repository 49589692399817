import { getFromCache, addToCache } from "./cache"

export const MAX_YEAR = (new Date()).getFullYear()
export const MIN_YEAR = 1700
export const SEARCH_LIMIT = 10
export interface GraveData {
    fullname: string,
    birth: number,
    death: number,
    graveid: string,
    extra1: string,
    extra2: string,
    extUrlFi?: string,
    extUrlSv?: string,
    extUrlEn?: string,
    extBtnTxtFi?: string,
    extBtnTxtSv?: string,
    extBtnTxtEn?: string,
}

export const emptyGrave: GraveData = {
  "fullname": "",
  "birth": 0,
  "death": 0,
  "graveid": "",
  "extra1": "",
  "extra2": ""
}

export interface GraveLocation {
  graveyard: string, 
  area: string, 
  block: string, 
  row: string, 
  graveId: string
}

export interface GraveYard {
  id: string
  name: string
}

export interface GraveYardAddress extends GraveYard {
  address: string,
  postcode: string
} 

export interface Congregation extends GraveYard {
  graveyards: GraveYardAddress[],
  routeUrl?: string
}

export interface Office {
  name: string,
  email: string,
  phone: string,
  officeHours: string,
  address: string,
  emailImageSrc: string
} 

export interface SearchQueryType {
    fullname: string
    birth: { min: number, max: number, exact: number | string, useRange : boolean }
    death: { min: number, max: number, exact: number | string, useRange : boolean }
    graveids: string[]
    congregationid: string
}

export const emptySearchQuery: SearchQueryType = {
    fullname: '',
    birth: { min: MIN_YEAR, max: MAX_YEAR, exact: '', useRange: false},
    death: { min: MIN_YEAR, max: MAX_YEAR, exact: '', useRange: false},
    graveids: [],
    congregationid: ''
}

export function getGraves (searchQuery: SearchQueryType, limit: number, offset: number | null, signal?: AbortSignal) {
    const graveApiUrl = String(process.env.REACT_APP_GRAVEAPI_URL)
    const body = JSON.stringify({ ...searchQuery, limit: limit, offset: Number(offset) })
    
    
    const isSameGraveQuery = searchQuery.graveids.length > 0 && searchQuery.graveids[0].length > 3

    // add possible cache, requesting api token, etc.


    return new Promise((resolve: (e: { totalcount: number, items :GraveData[]}) => void , reject) => {
        if( searchQuery.fullname.length < 2 && !isSameGraveQuery) {
          resolve({ totalcount: 0, items: []})
          return
        }

        const cachedItem: any = getFromCache(searchQuery, limit, offset)
        if( cachedItem !== undefined ) {
          resolve({ totalcount: cachedItem.totalcount , items: cachedItem.items})
          return
        }

        const headers: HeadersInit = new Headers();
        
        // note if you use any header, it must be allowed in Azure API management policy
        // headers.set('Content-Type', 'application/json');
        
        if( process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY ) {
          headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY )
        }

        fetch(graveApiUrl, {
          method: 'POST',
          body: body,
          headers: headers,
          signal: signal
        })
        .then(res => res.json())
        .then((result: { totalcount: number, items :GraveData[]}) => { 
          addToCache(searchQuery, limit, offset, result.totalcount, result.items)
          resolve(result) 
        },
        (error) => { 
          console.log(error)
          reject(error) 
        })
    })
  }


  export function warmupBackend() {
    const graveApiUrl = String(process.env.REACT_APP_GRAVEAPI_URL)
    const headers: HeadersInit = new Headers();
    // note if you use any header, it must be allowed in Azure API management policy
    // headers.set('Content-Type', 'application/json');
    if( process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY ) {
      headers.set('Ocp-Apim-Subscription-Key', process.env.REACT_APP_OCP_APIM_SUBSCRIPTION_KEY )
    }
  
    return new Promise((resolve: (result: any)=>void, reject) => {
      fetch(graveApiUrl + '?warmup=true', {
        method: 'GET',
        headers: headers
      })
      .then(res => res.json())
      .then((result: any) => { 
        resolve(result) 
      },
      (error) => { 
        reject(error) 
      })
    })
  }
  