import React, { useEffect, useState } from 'react'
import { Congregation, GraveData, GraveLocation } from '../../services/api'
import {
    Button,
    Grid,
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    CircularProgress
} from '@material-ui/core'
import { Place, People, KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import {
    BornBlackIcon,
    CrossBlackIcon,
    GraveyardBlackIcon
} from '../common/icons'
import { RouteWhiteIcon } from '../common/icons'
import { sameGraveListType } from './GraveListContainer'
import SameGraveList from './SameGraveList'
import _ from 'lodash'


const useStyles = makeStyles((theme) => ({
    card: {
        width: '100%',
        marginBottom: '5px',
        padding: '10px 20px',
        backgroundColor: '#FFFFFF'
    },
    dataItem: {
        textAlign: 'left',
        padding: '10px 0px 0px 10px',
        display: 'flex',
        alignItems: 'center'
    },
    header: {
        paddingRight: '10px',
        margin: 0
    },
    smallHeader: {
        margin: 0,
        fontWeight: 'normal',
        minWidth: '90px'
    },
    icon: {
        marginRight: '7px',
        marginBottom: '3px'
    },
    item: {
        verticalAlign: 'super',
    },
    itemNoIcon: {
        verticalAlign: 'middle'
    },
    data: {
        marginTop: '3px'
    },
    linkButton: {
        justifyContent: 'left',
        marginBottom: '10px',
        textAlign: 'left'
    },
    showMoreButton: {
        justifyContent: 'left'
    },
    shown: {
        overflow: 'hidden',
        maxHeight: '1000px',
        transition: 'max-height 1s ease-in-out'
    },
    hidden: {
        overflow: 'hidden',
        maxHeight: '0px',
        transition: 'max-height 1s cubic-bezier(0, 1, 0, 1)'
    },
    graveDataTable: {
        padding: '0px 5px 10px 5px'
    },
    address: {
        textAlign: 'left',
    },
    tableRow: {
        border: 'none',
        verticalAlign: 'top',
    },
    tableCellHeader: {
        border: 'none',
        paddingRight: '0px'
    },
    tableCellHeaderNoIcon: {
        paddingLeft: '48px',
        verticalAlign: 'middle'
    },
    tableCellData: {
        border: 'none',
        paddingTop: '10px'
    },
    locationHeader: {
        padding: '5px'
    }
}))

interface GraveRowProps {
    grave: GraveData,
    noLinks?: boolean
    sameGraveList: sameGraveListType[]
    index: number
    handleSameGraveListChange: (index: number, e: string, setVisible: boolean, setSameGraveIsLoaded: any) => void
    showMore?: boolean
}

export default function GraveRow({ grave, index, sameGraveList, handleSameGraveListChange, noLinks, showMore }: GraveRowProps) {
    const classes = useStyles()
    const { t } = useTranslation();

    const [mySameGraveList, setMySameGraveList] = useState(sameGraveList.filter((sameGraveItem) => sameGraveItem.listIndex === index))


    const sameGraveListVisible = mySameGraveList.length > 0 && mySameGraveList[0].visible
    const showSameGraveLink = !sameGraveListVisible && !(grave.graveid.endsWith('ZZ.ZZ') || grave.graveid.length === 2)
    const [graveyard, area, block, row, graveId] = grave && grave.graveid ? grave.graveid.split('-') : ['', '', '', '', '']
    const graveLocation: GraveLocation = { graveyard, area, block, row, graveId }
    const [sameGraveIsLoaded, setSameGraveIsLoaded] = useState(true)
    let congregations: Congregation[] = t('graveyards', { returnObjects: true })
    const congregation = congregations.find(congregation => congregation.graveyards.some(item => item.id === graveyard));
    const graveyardName = congregation?.graveyards.find(grave => grave.id === graveyard)?.name

    useEffect(() => {
        setMySameGraveList(sameGraveList.filter((sameGraveItem) => sameGraveItem.listIndex === index))
    }, [sameGraveList, index])


    function handleSameGraveClicked(e: any, setVisible: boolean) {
        setSameGraveIsLoaded(false)
        e.preventDefault()
        handleSameGraveListChange(index, grave.graveid, setVisible, setSameGraveIsLoaded)
    }
    function isListedGrave(item: GraveData) {
        return grave.fullname === item.fullname && grave.birth === item.birth && grave.death === item.death
    }

    const [isSameGraveExpanded, setIsSameGraveExpanded] = useState(false)

    return (
        <Card className={classes.card}>

            <Grid container>
                <div className={classes.dataItem}>
                    <h2 className={classes.header}>{grave.fullname}</h2>
                    <h3 className={classes.smallHeader}>{grave.birth} {"-"} {grave.death}</h3>
                </div>
                <Grid item xs={12} className={classes.dataItem}><h3>{congregation ? congregation.name + ", " : ""} {graveyardName}</h3></Grid>
                <Grid item xs={12} md={noLinks ? 12 : 7} className={classes.graveDataTable}>
                    <GraveDataTable grave={grave} graveLocation={graveLocation} showMore={showMore} />
                </Grid>
                {!noLinks &&
                    <Grid item xs={12} md={5}>
                        <GraveRowLinks
                            showSameGraveLink={showSameGraveLink}
                            handleSameGraveClicked={handleSameGraveClicked}
                            grave={grave}
                            graveLocation={graveLocation}
                            isSameGraveExpanded={isSameGraveExpanded}
                            setIsSameGraveExpanded={setIsSameGraveExpanded} />
                    </Grid>
                }
                {!sameGraveIsLoaded && <Grid item xs={12}><CircularProgress /></Grid>}
                {sameGraveListVisible &&
                    <Grid item xs={12} >
                        <SameGraveList
                            items={mySameGraveList[0].inSameGrave.filter(item => !isListedGrave(item))}
                            handleSameGraveClicked={handleSameGraveClicked}
                            isSameGraveExpanded={isSameGraveExpanded}
                            setIsSameGraveExpanded={setIsSameGraveExpanded}
                        />
                    </Grid>
                }
            </Grid>
        </Card>
    )
}

interface GraveTableProps {
    grave: GraveData,
    graveLocation: GraveLocation
    noLinks?: boolean
    showMore?: boolean
}
export function GraveDataTable({ grave, graveLocation, showMore }: GraveTableProps) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [displayMore, setDisplayMore] = useState(showMore)
    const { graveyard, area, block, row, graveId } = graveLocation

    return (
        <TableContainer>
            <Button
                className={classes.showMoreButton}
                fullWidth
                variant='contained'
                color='primary'
                startIcon={displayMore ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
                onClick={() => {
                    setDisplayMore(!displayMore)
                }}
            >
                {displayMore ? t('show-less') : t('show-more')}
            </Button>
            <div className={displayMore ? classes.shown : classes.hidden}>
                <Table aria-label="simple table" size='small'>
                    <TableBody >
                        <GraveTableRow icon={<BornBlackIcon fontSize='small' className={classes.icon} />}
                            header={t('birth-year')}
                            data={grave.birth} />
                        <GraveTableRow icon={<CrossBlackIcon fontSize='small' className={classes.icon} />}
                            header={t('death-year')}
                            data={grave.death} />
                        <GraveTableRow icon={<GraveyardBlackIcon fontSize='small' className={classes.icon} />}
                            header={t('graveyard')} data={<GraveAddress graveyard={graveyard} />} />
                        {area !== 'ZZZ' && area !== undefined && <GraveTableRow header={t('area')} data={area} />}
                        {block !== 'ZZ' && block !== undefined && <GraveTableRow header={t('block')} data={block} />}
                        {row !== 'ZZ' && row !== undefined && <GraveTableRow header={t('row')} data={row} />}
                        {graveId !== 'ZZ.ZZ' && graveId !== undefined && <GraveTableRow header={t('grave-id')} data={graveId} />}
                    </TableBody>
                </Table>
            </div>
        </TableContainer>
    )
}

function GraveAddress({ graveyard }: { graveyard: string }) {
    const { t } = useTranslation()
    const classes = useStyles()
    const congregations: Congregation[] = t('graveyards', { returnObjects: true })
    const grave = congregations.flatMap((congregation) => congregation?.graveyards).find(grave => grave.id === graveyard)

    return (
        <div className={classes.address}>
            {grave?.name}
            <address>
                {grave?.address} <br />
                {grave?.postcode}
            </address>
        </div>
    )
}

interface GraveTableRow {
    icon?: any,
    header: string
    data: string | number | JSX.Element
}

function GraveTableRow({ icon, header, data }: GraveTableRow) {
    const classes = useStyles()
    const headerClasses = icon ? classes.tableCellHeader :
        `${classes.tableCellHeader} ${classes.tableCellHeaderNoIcon}`
    const headerTextClass = icon ? classes.item : classes.itemNoIcon

    return (
        <TableRow className={classes.tableRow}>
            <TableCell className={headerClasses}>
                {icon}
                <span className={headerTextClass}>{header}</span>
            </TableCell>
            <TableCell className={classes.tableCellData}>
                {data}
            </TableCell>
        </TableRow>
    )
}

interface GraveRowLinks {
    grave: GraveData
    showSameGraveLink: boolean
    handleSameGraveClicked: (e: any, setVisible: boolean) => void
    graveLocation: GraveLocation
    isSameGraveExpanded: boolean
    setIsSameGraveExpanded: (isSameGraveExpanded: boolean) => void
}
function GraveRowLinks({ grave, showSameGraveLink, handleSameGraveClicked, graveLocation, isSameGraveExpanded, setIsSameGraveExpanded }: GraveRowLinks) {
    const { t } = useTranslation()
    let history = useHistory()
    const classes = useStyles()

    const showPlaceLink = grave.graveid.length !== 2
    return (
        <Grid container className={classes.graveDataTable}>
            {showPlaceLink &&
                <Button
                    className={classes.linkButton}
                    fullWidth
                    variant='contained'
                    color='primary'
                    startIcon={<Place />}
                    onClick={() => {
                        history.push({
                            pathname: `/map/${grave.graveid}/${grave.fullname}`,
                            state: grave
                        })
                    }}
                >
                    {t('show-map')}
                </Button>
            }
            <RouteLink grave={grave} graveLocation={graveLocation} />
            {/* <Button
                aria-label={t('move-to-page') + t('extend-grave-period')}
                className={classes.linkButton}
                fullWidth
                variant='contained'
                color='primary'
                startIcon={<InsertDriveFile />}
                href={t('extend-grave-period-link')}
                target='_blank'
            >
                {t('extend-grave-period')}
            </Button> */}
            {showSameGraveLink &&
                <Button
                    aria-expanded={isSameGraveExpanded}
                    className={classes.linkButton}
                    fullWidth
                    variant='contained'
                    color='primary'
                    startIcon={<People />}
                    onClick={(e) => {
                        setIsSameGraveExpanded(true)
                        handleSameGraveClicked(e, true)
                    }}
                >
                    {t('show-buried-in-same-grave')}
                </Button>
            }
        </Grid>
    )
}

function RouteLink({ grave, graveLocation }: { grave: GraveData, graveLocation: GraveLocation }) {
    const { t, i18n } = useTranslation()
    const classes = useStyles()
    const congregations: Congregation[] = t('graveyards', { returnObjects: true })
    const congregation = congregations.find((congregation: Congregation) => {
        return congregation.graveyards.some(graveyard => (graveyard.id === graveLocation.graveyard))
    })
    const graveyard = congregations.flatMap((congregation) => congregation?.graveyards).find(graveyard => (graveyard.id === graveLocation.graveyard))
    const graveyardName = graveyard ? graveyard.name : grave.graveid

    const language = (i18n.language ? i18n.language : 'fi')
    const graveidl = grave.graveid
    const longitude = grave.extra1
    const latitude = grave.extra2
    const routeUrl = congregation?.routeUrl?.replace(
        '[graveyard_name]', graveyardName
    ).replace(
        '[language]', language
    ).replace(
        '[latitude]', latitude
    ).replace(
        '[longitude]', longitude
    ).replace(
        '[grave]', graveidl
    )

    return (
        routeUrl === undefined ||
            grave.extra1 === '0' ||
            _.isEmpty(grave.extra1) ||
            grave.extra2 === '0' ||
            _.isEmpty(grave.extra2) ? null :
            <Button
                aria-label={t('move-to-page') + t('route-planner')}
                className={classes.linkButton}
                fullWidth
                variant='contained'
                color='primary'
                startIcon={<RouteWhiteIcon />}
                href={routeUrl}
                target='_blank'
            >
                {t('route-planner')}
            </Button>
    )
}
