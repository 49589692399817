import { atom } from "recoil";
import { emptySearchQuery, GraveData, SearchQueryType } from "../services/api";

export interface GraveList { graves: GraveData[], totalHits: number, searchQuery: SearchQueryType, limit: number, isLoaded: boolean }

const graveListAtom = atom({
    key: "graveListAtom",
    default: { graves: [], totalHits: 0, searchQuery: emptySearchQuery, limit: 0, isLoaded: false } as GraveList
});


export default graveListAtom;