import React from 'react'
import { SidepanelView } from '../common/SidepanelView'
import { useTranslation } from 'react-i18next'
import _ from 'lodash'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { LogoCarousel } from '../common/LogoCarousel'
import { Congregation } from '../../services/api'

const useStyles = makeStyles((theme) => ({
    page: {
        textAlign: 'left',
        margin: '5%'
    },
    accordion: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    }, 
    items: {
        margin: '10px 0'
    },
}))

interface ServiceInfoViewViewProps {
    setActivePage: (page: number) => void
}

export function ServiceInfoViewView ({setActivePage}: ServiceInfoViewViewProps) {    
    const { t } = useTranslation()
    const classes = useStyles()
    const congregations: Congregation[] = t('graveyards', { returnObjects: true })

    React.useEffect(() => {
        setActivePage(1)
    })

    return (
        <SidepanelView
            pageContent = {
                <div className={classes.page} tabIndex={0}>
                    <h1>{t('infopage-title')}</h1>
                    <p>{t('infopage-graveyards')}</p>
                    {congregations.map((congregation, id) => (
                        <div className={classes.items} key={id}>
                            <Accordion>
                                <AccordionSummary
                                classes={{expanded: classes.accordion, content: classes.accordion, root: classes.accordion }}
                                expandIcon={<ExpandMoreIcon />}
                                >
                                <h2>{congregation.name}</h2>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ul>
                                    {congregation.graveyards.map((graveyard, index) => (
                                        <li key={index}>{graveyard.name}</li>) 
                                    )}
                                    </ul>
                                </AccordionDetails>
                            </Accordion>
                        </div>
                    ))}
                    <p>{t('infopage-others-graveyards')}</p>
                    <p>{t('infopage-info-not-found')}</p>
                    <ul>
                        { _.map(t('infopage-info-not-found-list', { returnObjects: true }), (reason: string, index: string) => 
                            <li key={index}>{reason}</li>) 
                        }
                    </ul>
                    <p>{t('infopage-contact')}</p>
                </div>
            }
            sidepanelContent = {<LogoCarousel />}
        />
    )
}