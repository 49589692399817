import React from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { SearchQueryType } from '../../services/api'
import { FilterPanel } from './FilterPanel'
import { AppBar, IconButton, Button, Toolbar, Typography } from '@material-ui/core'
import { SearchWhiteIcon } from '../common/icons'

const useStyles = makeStyles((theme) => ({
    header: {
        top: '0px',
        boxShadow: '0 0 5px #000',
    },
    footer: {
        bottom: '0px',
        width: '100%',
        boxShadow: '0 0 5px #000',
        cursor: 'pointer'
    },
    closeButton: {
        marginLeft: 'auto'
    },
    iconButton: {
        borderRadius: '4px',
        marginLeft: '-24px',
        minWidth: '1em',
        fontSize: '2.3em',
        lineHeight: '1em',
        padding: '8px',
        margin: '4px',
        width: '3em'
    },
    icon: {
        fontSize: '1em',
    },
}))

interface FilterBoxProps {
    handleSubmit: (e: React.ChangeEvent<{}>) => void
    handleFilterChange: (searchParameters: SearchQueryType) => void
    searchParameters: SearchQueryType
    setShowFilters: (showFilters: boolean) => void

}

export function FilterModal({ searchParameters, handleSubmit, handleFilterChange, setShowFilters }: FilterBoxProps) {
    const classes = useStyles()
    const { t } = useTranslation()

    return (
        <>
            <AppBar position='static' elevation={0} color='secondary' className={classes.header}>
                <Toolbar>
                    <Typography variant='h6' noWrap>
                        {t('filter-title')}
                    </Typography>
                    <Button onClick={() => setShowFilters(false)} 
                            variant='contained' 
                            size='small' 
                            color='primary'
                            className={classes.closeButton}>
                        {t('close')}
                    </Button>
                </Toolbar>
            </AppBar>
            <FilterPanel searchParameters={searchParameters} handleFilterChange={handleFilterChange} />
            <AppBar position='static' elevation={0} className={classes.footer}>
                <Toolbar onClick={(e) => {
                                    setShowFilters(false)
                                    handleSubmit(e)}} >
                    <IconButton onClick={(e) => {
                                    setShowFilters(false)
                                    handleSubmit(e)}} 
                                className={classes.iconButton}>
                        <SearchWhiteIcon size='1em' className={classes.icon} />
                    </IconButton>
                    <Typography variant='h6' noWrap>
                        {t('search-with-filters')}
                    </Typography>
                </Toolbar>
            </AppBar>
        </>
    )
}
