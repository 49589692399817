import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette : { 
        primary: {
            main: '#005293'
        },
        secondary: {
            main: '#FFFFFF'
        },
        background: {
            default: "#005293"
            },
    },
    overrides : {
        MuiTab: {
            root: {
                textTransform: 'none'
            }
        },
    },

})

theme.typography.h5 = { 
        ...theme.typography.h5,
        fontSize: '1.2rem',
        fontFamily: [ 'Martti' ]
    }
theme.typography.root = {
    ...theme.typography.root,
    fontFamily: [ 'Martti' ]
}



export default theme;