import React, {} from 'react'
import { SmallLabel } from '../common/SmallLabel'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
    item: {
        marginBottom: '1em',
        width: '100%',
    },
    main: {
        marginTop: '0.2em',
        fontSize: '1.2em'
    },
    secondary: {
        marginTop: '0.5em',
        opacity: '0.7',
        fontSize: '1em',
    },
    image:{
        maxWidth: "100%",
        height: "auto"
    }
}))

interface ContactItemProps {
    label: string
    mainText: string | JSX.Element
    secondaryText: string
}

export function ContactItem ({label, mainText, secondaryText}: ContactItemProps) {
    const classes = useStyles() 
    
    return (
        <div className={classes.item}>
            <SmallLabel text={label} />
            <div className={classes.main}>
                {mainText}
            </div>
            <div className={classes.secondary}>
                {secondaryText}
            </div>
        </div>
    )
}

export function EmailItem({imageSrc, ariaLabel, imgAlt}: {imageSrc: string, ariaLabel: string, imgAlt: string}) {
    const classes = useStyles() 

    return (
        <div role='img' aria-label={ariaLabel}>
            {imageSrc && <img className={classes.image} src={imageSrc} alt={imgAlt}/>}
        </div>
    )
}