import React from 'react'
import { SidepanelView } from '../common/SidepanelView'
import { Link, Typography } from '@material-ui/core'
import { useHistory } from "react-router-dom"
import { useTranslation } from 'react-i18next'
import { makeStyles } from '@material-ui/core/styles'
import { LogoCarousel } from '../common/LogoCarousel'

const useStyles = makeStyles((theme) => ({
    link: {
        marginBottom: '10px'
    }
  }))

interface AccessibilityStatementViewProps {
    setActivePage: (page: number | false) => void
}

export function AccessibilityStatementView ({setActivePage}: AccessibilityStatementViewProps) {
    React.useEffect(() => {
        setActivePage(false)
    })

    return (
        <SidepanelView
            pageContent = {
                <div style={{textAlign:'left', margin: '2em'}} tabIndex={0}>
                    <AccessabilityStatement />
                </div>
            }
            sidepanelContent = {<LogoCarousel />}
        />
    )
}

function AccessabilityStatement() {
    const history = useHistory()
    const { t } = useTranslation()
    const classes = useStyles()

    return (
        <article>
            <h1>{t('accessibility-main-header')}</h1>
            <section>
                <p>{t('accessibility-scope')}</p>
                <p>{t('accessibility-validator')}</p>
            </section>

            <section>
                <h2>{(t('accessibility-state-header'))}</h2>
                <p>{t('accessibility-state-text')}</p>
            </section>
            
            <section>
                <h2>{t('accessibility-non-accesss-header')}</h2>                               
                <p>
                    {t('accessibility-non-access-para1')}
                </p>
                <p>
                    {t('accessibility-non-access-para2')}
                </p>
                </section>
            
            <section>
                <h2>
                    {t('accessibility-did-you-notice-header')} 
                </h2>
                <p>
                    {t('accessibility-did-you-notice-text')}
                </p>
                <Link aria-label={t('move-to-page') + t('contactpage-bottom-link-url')} target='_blank' href={t('contactpage-bottom-link-url')} >
                    <Typography variant='inherit' component='h3' display='block' className={classes.link}>
                        {t('contactpage-bottom-link-text')}
                    </Typography>
                </Link>
                <Link href="/contact"  
                        onClick={(event: React.SyntheticEvent) => {
                            event.preventDefault()
                            history.push('/contact')
                        }}>
                    <Typography variant='inherit' component='h3' display='block' className={classes.link}>
                        {t('tab-info-contact')}
                    </Typography>
                </Link>
            </section>
                    
            <section>
                <h2>{t('accessibility-supervisory-authority-header')}</h2>
                <p>
                    {t('accessibility-supervisory-authority-text-start')} 
                    <a href={t('accessibility-supervisory-authority-link-url')} target="_blank" rel='noopener noreferrer'>
                        {t('accessibility-supervisory-authority-link-text')}     
                    </a>
                    {t('accessibility-supervisory-authority-text-end')}
                </p>

                <h2>{t('accessibility-supervisory-contact-header')}</h2>
                <p>
                    {t('accessibility-supervisory-contact-office')}<br />
                    {t('accessibility-supervisory-contact-unit')}<br />
                    {t('accessibility-supervisory-contact-url')}  <br />
                    {t('accessibility-supervisory-contact-email')}<br />
                    {t('accessibility-supervisory-contact-tel')}            
                </p>
            </section>
        </article>
    )
}