import React from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Link, Typography } from '@material-ui/core'
import { ContactItem } from './ContactItem'
import { makeStyles } from '@material-ui/core/styles'
import { SidepanelView } from '../common/SidepanelView'
import { useTranslation } from 'react-i18next'
import { EmailItem} from './ContactItem'
import { Office } from '../../services/api'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { LogoCarousel } from '../common/LogoCarousel'

const useStyles = makeStyles((theme) => ({
    topText: {
        marginBottom: '1em',
    },
    items: {
        margin: '10px 0'
    },
    bottomText: {
        marginBottom: '10px',
    },
    link: {
        marginBottom: '1em'
    },
    hr: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
        color: '#eeeeee',
        marginBottom: '1em'
    },
    page: {
        textAlign: 'left',
        margin: '5%'
    },
    accordion: {
        marginTop: '0px !important',
        marginBottom: '0px !important'
    }
}))

interface ContactViewViewProps {
    setActivePage: (page: number) => void
}

export function ContactViewView ({setActivePage}: ContactViewViewProps) {
    React.useEffect(() => {
        setActivePage(2)
    })

    return (
        <SidepanelView
            pageContent = {<PageContent />}
            sidepanelContent = {<LogoCarousel />}
        />
    )
}

export function PageContent () {
    const { t } = useTranslation()
    const classes = useStyles()
    const offices: Office[] = t('contactpage-offices', { returnObjects: true })

    return (
        <div className={classes.page} tabIndex={0}>
            <h1>{t('contactpage-title')}</h1>
            <div className={classes.topText}> 
                {t('contactpage-top-text')}
            </div>
            
            {offices.map((office, id) => (
                <div className={classes.items} key={id}>
                    <Accordion>
                        <AccordionSummary
                        classes={{expanded: classes.accordion, content: classes.accordion, root: classes.accordion }}
                        expandIcon={<ExpandMoreIcon />}
                        >
                        <h2> {office.name}</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item md={4} xs={12}>
                                    <ContactItem label={t('contactpage-phone')} 
                                        mainText={office.phone} 
                                        secondaryText={office.officeHours}/>
                                    <hr className={classes.hr} aria-hidden/>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <ContactItem label={t('contactpage-email.label')}
                                        mainText={
                                            <EmailItem imageSrc={office.emailImageSrc} 
                                                    ariaLabel={office.email} 
                                                    imgAlt={t('contactpage-email.image-alt')}/>
                                        }
                                        secondaryText={t('contactpage-email.secondary-text')}/>
                                    <hr className={classes.hr} aria-hidden/>
                                </Grid>
                                <Grid item md={4} xs={12}>
                                    <ContactItem label={t('contactpage-office')}
                                            mainText={office.address}
                                            secondaryText={t('contactpage-closed')}/>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </div>
            ))}

            <div className={classes.bottomText}>
                <h2>{t('contactpage-feedback-header')}</h2>
                <p>{t('contactpage-feedback-text')}</p>    
            </div> 

            <Link aria-label={t('move-to-page') + t('contactpage-bottom-link-text')} className={classes.link} href={t('contactpage-bottom-link-url')} target="_blank">
                <Typography variant='inherit' component='h3' display='block'>
                    {t('contactpage-bottom-link-text')}
                </Typography>
            </Link>
        </div>
    )
}