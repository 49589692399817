import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SearchQueryType, Congregation, GraveYardAddress } from '../../services/api'
import { makeStyles } from '@material-ui/core/styles'
import { SmallLabel } from '../common/SmallLabel'
import { FormControl, Grid, TextField } from '@material-ui/core'
import { GraveyardBlackIcon } from '../common/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'

const useStyles = makeStyles((theme) => ({
    marginLeft: {
        marginLeft: '10px',
    },
    marginTop: {
        marginTop: '5px',
    },
    icon: {
        marginRight: '7px',
        marginBottom: '10px',
    },
    formControl: {
        width: '100%',
        paddingBottom: '20px'
    },
    selectLabel: {
        background: 'white'
    },
    header: {
        paddingBottom: '10px'
    }
}))

interface GraveYardFilterProps {
    changeGraveYards: (newValue: string[]) => void
    changeCongregation: (newValue: string) => void
    searchParameters: SearchQueryType
}

const getCongregations = (allCongregations: Congregation[], emptyCongregation: Congregation, emptyGraveyad: GraveYardAddress) => {
    const allGraveYards = allCongregations.flatMap((congregation) => congregation.graveyards)
    emptyCongregation.graveyards = allGraveYards;
    allCongregations.unshift(emptyCongregation)

    allCongregations.forEach((congregation) => {
        congregation.graveyards.unshift(emptyGraveyad)
    })

    return allCongregations
}

export function GraveYardFilter({ changeGraveYards, changeCongregation, searchParameters }: GraveYardFilterProps) {
    const classes = useStyles()
    const { t } = useTranslation()
    const allCongregationsOption: Congregation = { "id": "all", "name": t('all-congregations'), "graveyards": []}
    const allGraveyardsOption: GraveYardAddress = t('all-graveyards', { returnObjects: true })
    const [selectedCongregation, setSelectedCongregation] = useState<Congregation>(allCongregationsOption)
    const [selectedGraveYard, setSelectedGraveYard] = useState<GraveYardAddress>(allGraveyardsOption)
    const congregations = getCongregations(t('graveyards', { returnObjects: true }), allCongregationsOption, allGraveyardsOption)

    useEffect(() => {
        const congregations = getCongregations(t('graveyards', { returnObjects: true }),{ "id": "all", "name": t('all-congregations'), "graveyards": []}, t('all-graveyards', { returnObjects: true }))
        const currentCongregation = congregations.find((congregation) => congregation.id === searchParameters.congregationid)
        const currentgraveyard = congregations.flatMap((congregation) => congregation.graveyards).find((graveyard) => graveyard.id === searchParameters.graveids[0])
        if (currentCongregation){
            setSelectedCongregation(currentCongregation)
        }
        if (currentgraveyard) {
            setSelectedGraveYard(currentgraveyard)
        }
    }, [searchParameters, t]);

    const onCongregationChange = (event: any, value: Congregation) => {
        setSelectedCongregation(value)
        setSelectedGraveYard(value.graveyards[0])
        changeCongregation(value.id)
        if (value.id === "all"){
            changeGraveYards([])
        } else {
            changeGraveYards(value.graveyards.map((item) => item.id))
        }
    }

    const onGraveyardChange = (event: any, value: GraveYardAddress) => {
        setSelectedGraveYard(value)
        if (value.id === "all") {
            changeGraveYards(selectedCongregation.graveyards.map((item) => item.id))
        } else {
            changeGraveYards([value.id])
        }
    }
    
    return (
        <Grid container spacing={0} className={classes.marginTop}>
            <Grid item className={classes.header} xs={12}>
                <SmallLabel text={t('graveyard')}
                            icon={<GraveyardBlackIcon fontSize='small' className={classes.icon} />}
                />
            </Grid>
            <FormControl variant="outlined" className={classes.formControl}>
            <Autocomplete
                    value={selectedCongregation}
                    id="congregation-combo-box"
                    options={congregations}
                    disableClearable
                    getOptionLabel={(graveyard) => graveyard.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={onCongregationChange}
                    renderInput={(params) => <TextField {...params} label={t('select-congregation')} variant="outlined" />}
                />
            </FormControl>
            <FormControl variant="outlined" className={classes.formControl}>
                <Autocomplete
                    value={selectedGraveYard}
                    disableClearable
                    id="graveyard-combo-box"
                    options={selectedCongregation.graveyards}
                    getOptionLabel={(graveyard) => graveyard.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    onChange={onGraveyardChange}
                    renderInput={(params) => <TextField {...params} label={t('select-graveyard')} variant="outlined" />}
                />
            </FormControl>
        </Grid>
    )
}
