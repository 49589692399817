import React, { useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { useTranslation } from 'react-i18next'
import { SearchQueryType, MIN_YEAR, MAX_YEAR } from '../../services/api'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { makeStyles } from '@material-ui/core/styles'
import { NumberField } from './NumberField'
import { Checkbox, Grid } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
    centered: {
        textAlign: 'center',
        marginBottom: '3px',
    },
    slider: {
        width: '96%',
        margin: '0 3%',
    },
    bottomLine: {
        borderColor: '#eeeeee',
        borderWidth: '1px',
        marginBottom: '1em',
        width: '95%',
    },
}))

interface YearFilterProps {
    changeYears: (
        field: 'birth' | 'death',
        newValue: { min: number; max: number; exact: number | string; useRange: boolean }
    ) => void
    searchParameters: SearchQueryType
    field: 'birth' | 'death'
    label: string
    icon: JSX.Element
    yearErrors: boolean[]
}

export function YearFilter({ changeYears, searchParameters, field, label, icon, yearErrors }: YearFilterProps) {
    const classes = useStyles()
    const { t } = useTranslation()
    const [resetRange, setResetRange] = useState(-1)
    const [exact, setExact] = useState(searchParameters[field].exact)
    const [range, setRange] = useState([searchParameters[field].min, searchParameters[field].max])
    const [text, setText] = useState([searchParameters[field].min, searchParameters[field].max])

    React.useEffect(() => {
        setExact(searchParameters[field].exact)
        setRange([searchParameters[field].min, searchParameters[field].max])
        setText([searchParameters[field].min, searchParameters[field].max])
      }, [searchParameters, field]);

    function handleUseRange() {
        const exactYear = Number(searchParameters[field].exact) // 0 if text in value field
        const newUseRange = !searchParameters[field].useRange
        if (exactYear > 0 && exactYear !== resetRange && newUseRange) {
            setResetRange(Number(exactYear))
            const min = exactYear - 5 > MIN_YEAR ? exactYear - 5 : MIN_YEAR
            const max = exactYear + 5 < MAX_YEAR ? exactYear + 5 : MAX_YEAR
            changeYears(field, { ...searchParameters[field], useRange: newUseRange, min: min, max: max })
        } else {
            changeYears(field, { ...searchParameters[field], useRange: newUseRange })
        }
    }
    function handleExactChange(value: any) {
        setExact(value)
        const newValue = Number(value)
        if ((newValue <= MAX_YEAR && MIN_YEAR <= newValue) || value === '') {
            changeYears(field, { ...searchParameters[field], exact: value })
        }
    }
    function handleExactBlur(value: any) {
        changeYears(field, { ...searchParameters[field], exact: value })
    }
    function handleMinRangeChange(value: any) {
        setText([value, text[1]])
        const newValue = Number(value)
        if (newValue <= MAX_YEAR && MIN_YEAR <= newValue) {
            changeYears(field, { ...searchParameters[field], min: value })
            setRange([newValue, range[1]])
        }
    }
    function handleMaxRangeChange(value: any) {
        setText([text[0], value])
        const newValue = Number(value)
        if (newValue <= MAX_YEAR && MIN_YEAR <= newValue) {
            changeYears(field, { ...searchParameters[field], max: value })
            setRange([range[0], newValue])
        }
    }
    function handleMinRangeBlur() {
        let newValue = Number(text[0])
        if( newValue === 0 ) {
            newValue = MIN_YEAR
        }
        if (newValue >= MAX_YEAR ) {
            changeYears(field, { ...searchParameters[field], min: MAX_YEAR })
            setRange([range[1],MAX_YEAR])
            setText([range[1],MAX_YEAR])
        }
        else if (newValue <= MIN_YEAR ) {
            changeYears(field, { ...searchParameters[field], min: MIN_YEAR })
            setRange([MIN_YEAR,range[1]])
            setText([MIN_YEAR,range[1]])
        }
        else if( newValue > range[1] ) {
            setRange([range[1], newValue])
            setText([range[1], newValue])
        }
    }
    function handleMaxRangeBlur() {
        let newValue = Number(text[1])
        if( newValue === 0 ) {
            newValue = MAX_YEAR
        }
        if (newValue >= MAX_YEAR ) {
            changeYears(field, { ...searchParameters[field], max: MAX_YEAR })
            setRange([range[0], MAX_YEAR])
            setText([range[0], MAX_YEAR])
        }
        if (newValue <= MIN_YEAR ) {
            changeYears(field, { ...searchParameters[field], max: MIN_YEAR })
            setRange([MIN_YEAR,range[0]])
            setText([MIN_YEAR,range[0]])
        }
        else if( newValue < range[0] ) {
            setRange([newValue, range[0]])
            setText([newValue, range[0]])
        }
    }  

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <NumberField
                    id={field + '-exact'} 
                    maxWidth={true} 
                    value={exact} 
                    onBlur={(e: any) => handleExactBlur(e.target.value)}
                    onChange={(e: any) => handleExactChange(e.target.value)}
                    label={label} 
                    icon={icon}
                    error={field === 'birth' ? yearErrors[0] :
                            field === 'death' ? yearErrors[1]: false}
                />
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox
                            color='primary'
                            checked={searchParameters[field].useRange}
                            onClick={() => handleUseRange()}
                        />
                    }
                    label={t('use-range')}
                />
            </Grid>
            {searchParameters[field].useRange && (
                <Grid item xs={12}>
                    <Slider
                        className={classes.slider}
                        value={range}
                        onChange={(e: any, values: number | number[]) => {
                            if (typeof values !== 'number') {
                                setRange(values)
                                setText(values)
                            }
                        }}
                        onChangeCommitted={(e: any, values: number | number[]) =>
                            typeof values !== 'number' &&
                            changeYears(field, { ...searchParameters[field], min: values[0], max: values[1] })
                        }
                        min={MIN_YEAR}
                        max={MAX_YEAR}
                        aria-labelledby='range-slider'
                        getAriaValueText={(value: any) => value}
                    />
                    <Grid container spacing={2} alignItems='center' className={classes.centered}>
                        <Grid item xs={5}>
                            <NumberField
                                id={field + '-slider-start'}
                                center={true}
                                value={text[0]}
                                onChange={(e: any) => handleMinRangeChange(e.target.value)}
                                onBlur={() => handleMinRangeBlur()}
                                error={field === 'birth' ? yearErrors[3] :
                                        field === 'death' ? yearErrors[5]: false}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <div>-</div>
                        </Grid>
                        <Grid item xs={5}>
                            <NumberField
                                id={field + '-slider-end'}
                                center={true}
                                value={text[1]}
                                onChange={(e: any) => handleMaxRangeChange(e.target.value)}
                                onBlur={() => handleMaxRangeBlur()}
                                error={field === 'birth' ? yearErrors[4] :
                                         field === 'death' ? yearErrors[6]: false}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}

            <hr className={classes.bottomLine} />
        </Grid>
    )
}
