import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import { SmallLabel } from '../common/SmallLabel'
import './NumberField.css'

const useStyles = makeStyles((theme) => ({
    maxWidth: {
        width: '100%',
    },
}))

function NumberFieldLabel({label, icon}: {label: string, icon?: JSX.Element}) {
    return(
        <SmallLabel text={label} icon={icon} />
    )
}

interface NumberFieldProps {
    id: string
    maxWidth?: boolean
    value: any 
    onChange: (e: any) => void
    onBlur?: (e:any) => void
    label?: string
    icon?: JSX.Element
    center?: boolean
    error?: boolean
}

export function NumberField(props: NumberFieldProps) {
    const classes = useStyles()
    return (
        <div>
            <label htmlFor={props.id}>
                {props.label && <NumberFieldLabel label={props.label} icon={props.icon} /> }
            </label>
            <TextField
                className={props.maxWidth ? classes.maxWidth : undefined}
                id={props.id}
                error={props.error}
                type='number'
                onChange={(e) => {
                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                    props.onChange(e)
                }}
                onBlur={props.onBlur}
                variant='outlined'
                value={props.value === undefined ? '' : props.value}
                inputProps={props.center ? { style: { textAlign: 'center' }} : undefined}
            />
        </div>
    )
}
