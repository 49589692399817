import React from 'react'
import Icon from '@material-ui/core/Icon'
const IMAGE_PATH = '/images/'

export function CrossBlackIcon(props: any) {
    return (
        <Icon {...props}>
            <img src={IMAGE_PATH + 'cross-black.svg'} alt="cross" />
        </Icon>
    )
}

export function BornBlackIcon(props: any) {
    return (
        <Icon {...props}>
             <img src={IMAGE_PATH + 'born-black.svg'} alt="born" />
        </Icon>
    )
}

export function GraveyardBlackIcon(props: any) {
    return (
        <Icon {...props}>
             <img src={IMAGE_PATH + 'graveyard-black.svg'} alt="graveyard" />
        </Icon>
    )
}

export function FiltersBlueIcon(props: any) {
    const styles = props.size ? { width: props.size, height: props.size } : {}
    return (
        <Icon {...props}>
            <img src={IMAGE_PATH + 'filters-blue.svg'} alt="filters" style={styles}/>
        </Icon>
    )
}
export function BackBlackIcon(props: any) {
    const styles = props.size ? { width: props.size, height: props.size } : {}
    return (
        <Icon {...props}>
            <img src={IMAGE_PATH + 'back-black.svg'} alt="back" style={styles}/>
        </Icon>
    )
}

export function SearchWhiteIcon(props: any) {
    const styles = props.size ? { width: props.size, height: props.size } : {}
    return (
        <Icon {...props}>
            <img src={IMAGE_PATH + 'search-white.svg'} alt="search" style={styles}/>
        </Icon>
    )
}

export function RouteWhiteIcon(props: any) {
    const styles = props.size ? { width: props.size, height: props.size } : {}
    return (
        <Icon {...props}>
            <img src={IMAGE_PATH + 'route-white.svg'} alt="route" style={styles}/>
        </Icon>
    )
}