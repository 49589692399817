import React, { useState, useEffect } from 'react'
import { emptySearchQuery, getGraves, GraveData, SearchQueryType } from '../../services/api'
import { GraveListViewView } from './GraveListViewView'
import { GraveListPagination } from './GraveListPagination'
import { FilterPanel } from '../filters/FilterPanel'
import { Sidepanel } from '../common/Sidepanel'
import { useTranslation } from 'react-i18next'
import { Alert } from '@material-ui/lab'
import { Snackbar } from '@material-ui/core'
import { useRecoilState } from 'recoil'
import graveListAtom from '../../atoms/graveList'

interface GraveListContainerProps {
    setActivePage: (page: number | false) => void
}

export interface sameGraveListType {
    listIndex: number
    graveid: string
    inSameGrave: GraveData[]
    visible: boolean
}
const emptySameGraveList: sameGraveListType[] = []


export function GraveListContainer({ setActivePage }: GraveListContainerProps) {

    const [graveList, setGraveList] = useRecoilState(graveListAtom);
    const [error, setError] = useState(false)
    const [isLoaded, setIsLoaded] = useState(graveList.isLoaded)
    const [items, setItems] = useState([...graveList.graves])
    const [page, setPage] = useState(1)
    const [totalHits, setTotalHits] = useState(graveList.totalHits)
    const [searchQuery, setSearchQuery] = useState({ ...graveList.searchQuery })
    const [sameGraveList, setSameGraveList] = useState(emptySameGraveList)
    const { t } = useTranslation()

    // needed to re render when search/select is used in autocomplete
    useEffect(() => {
        setActivePage(false)
        if (graveList.isLoaded) {
            setItems([...graveList.graves])
            setTotalHits(graveList.totalHits)
        }
        setSearchQuery({ ...graveList.searchQuery })
        setIsLoaded(graveList.isLoaded)
        setSameGraveList(emptySameGraveList)
    }, [graveList, setActivePage])

    // needed to wait for changing filters: searchQuery in graveList, then fetch data again
    useEffect(() => {
        if (!graveList.isLoaded) {
            setIsLoaded(false)
            searchQuery.graveids = searchQuery.graveids.filter((id) => id !== "all")
            getGraves(searchQuery, graveList.limit, 0 * graveList.limit).then(
                (result: { totalcount: number, items: GraveData[] }) => {
                    setTotalHits(result ? result.totalcount : graveList.totalHits)
                    setPage(1)
                    setItems(result && result.items ? result.items : [...graveList.graves])
                    setIsLoaded(true)
                    setSameGraveList(emptySameGraveList)
                },
                (error) => {
                    setIsLoaded(true)
                    setError(true)
                }
            )
        }
    }, [searchQuery, graveList.isLoaded, graveList.graves, graveList.limit, graveList.totalHits])


    function handlePageChange(event: any, value: number) {
        fetchData(value)
    }

    function fetchData(page: number) {
        setIsLoaded(false)
        searchQuery.graveids = searchQuery.graveids.filter((id) => id !== "all")
        getGraves(searchQuery, graveList.limit, (page - 1) * graveList.limit).then(
            (result: { totalcount: number, items: GraveData[] }) => {
                setTotalHits(result ? result.totalcount : graveList.totalHits)
                setPage(page)
                setItems(result && result.items ? result.items : [...graveList.graves])
                setIsLoaded(true)
                setSameGraveList(emptySameGraveList)
            },
            (error) => {
                setIsLoaded(true)
                setError(true)
            }
        )
    }
    function handleSameGraveListChange(listIndex: number, graveid: string, setVisible: boolean, setSameGraveIsLoaded: any) {
        const sameListIndex = sameGraveList.findIndex(item => item.listIndex === listIndex)

        if (sameListIndex < 0 && setVisible) {
            const query = { ...emptySearchQuery, graveids: [graveid] }
            getGraves(query, 100, 0).then(
                (result: { totalcount: number, items: GraveData[] }) => {
                    // gravelist with same grave list button clicked
                    const openedSameGraveList = { listIndex: listIndex, graveid: graveid, inSameGrave: result.items, visible: true }

                    // same results in other gravelist graves
                    const sameResults = items.flatMap((item, index) => {
                        if (index !== listIndex && item.graveid === graveid) {
                            return [{ listIndex: index, graveid: graveid, inSameGrave: result.items, visible: false }]
                        }
                        return []
                    })

                    const newList = [...sameGraveList, openedSameGraveList, ...sameResults]
                    setSameGraveIsLoaded(true)
                    setSameGraveList(newList)
                },
                (error) => {
                    setSameGraveIsLoaded(true)
                    setError(true)
                }
            )
        }
        else {
            setSameGraveIsLoaded(true)
            const newItem = { ...sameGraveList[sameListIndex], visible: setVisible }
            const newList = sameGraveList.filter(item => item.listIndex !== listIndex)
            setSameGraveList([...newList, newItem])
        }
    }

    return (
        <>
            <Snackbar open={error} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity="error">
                    {t('gravelist-error')}
                </Alert>
            </Snackbar>
            <GraveListViewView
                items={items}
                isLoaded={isLoaded}
                totalHits={totalHits}
                sameGraveList={sameGraveList}
                handleSameGraveListChange={handleSameGraveListChange}
                pagination={
                    <GraveListPagination
                        page={page}
                        count={Math.ceil(totalHits / 10)}
                        handlePageChange={handlePageChange}
                    />
                }
                filters={
                    <Sidepanel title={t('filter-title')}>
                        <FilterPanel
                            handleFilterChange={(newQuery: SearchQueryType) =>
                                setGraveList({ graves: [], totalHits: 0, searchQuery: newQuery, isLoaded: false, limit: graveList.limit })}
                            searchParameters={searchQuery}
                        />
                    </Sidepanel>
                }
            />
        </>
    )
}