import React from 'react'
import { PhotoBanner } from './PhotoBanner'
import { SidepanelView } from '../common/SidepanelView'
import { useTranslation } from 'react-i18next'
import { LogoCarousel } from '../common/LogoCarousel'

interface MainViewViewProps {
    setActivePage: (page: number | false) => void
}

export function MainViewView({setActivePage}: MainViewViewProps) {
    const { t } = useTranslation()

    React.useEffect(() => {
        setActivePage(0)
    })

    // console.log('build environment: ', process.env.NODE_ENV)
    // console.log('configuration:     ', process.env.REACT_APP_ENVIRONMENT_NAME) 
    // console.log('API:               ', process.env.REACT_APP_GRAVEAPI_URL) 
    
    return (
        <>
            <PhotoBanner />
            <SidepanelView
                pageContent = {
                    <div style={{textAlign:'left', margin: '2em'}}>
                    <h1>{t('frontpage-title')}</h1>
                    <p>{t('frontpage-text-1')}</p>
                    <p>{t('frontpage-text-2')}</p>                    
                </div>
                }
                sidepanelContent = {<LogoCarousel />}
            />
        </>
    )
}

